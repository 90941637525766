import React from "react"
import HeroHead from "./HeroHead/HeroHead"

const Header = ({menu = false}) => (
  <div>
    <section className="hero">
      <HeroHead menu={menu}></HeroHead>
    </section>
  </div>
)

export default Header
