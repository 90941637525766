import React from "react"
import Layout from "../components/Layout/Layout"
import Pricing from "../components/PricingTable/PricingTable"
import HowItWorks from "../components/HowItWorks/HowItWorks"
import SEO from "../components/Seo/Seo"
import { RedocStandalone } from 'redoc';
import api from "../apis/api.json";
import theme from "../apis/theme.json";

const ApiPage = function () {
  console.log(api);

  return (
    <Layout header={true} footer={true}>
      <SEO title="Home" />
        <div className="container is-paddingless">
          <div className="columns">
            <div className="column content is-medium">
              <RedocStandalone
                specUrl={api}
                options={{
                  nativeScrollbars: true,
                  theme: theme,
                  disableSearch: true,
                  sortPropsAlphabetically: true,
                }}/>
            </div>
          </div>
        </div> 
    </Layout>)
}

export default ApiPage;
