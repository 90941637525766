import React from "react"
import { Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./HeroHead.scss"

const HeroHead = function ({ menu = true }) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "venuebox-logo.png" }) {
        childImageSharp {
          fixed(width: 130, height: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className="hero-head">
      <nav
        className="navbar is-spaced  is-light"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <Img fixed={data.file.childImageSharp.fixed} />
            </Link>
            <a
              role="button"
              className="navbar-burger burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarTopMain"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          {menu && (
            <div className="navbar-menu" id="navbarTopMain">
              <div className="navbar-end">
                <Link
                  to="/platforms"
                  className="navbar-item has-text-weight-semibold"
                >
                  For Ticket Platforms
                </Link>
                <Link
                  to="/organisers"
                  className="navbar-item has-text-weight-semibold"
                >
                  For Event Organisers
                </Link>
                <Link
                  to="/pricing"
                  className="navbar-item has-text-weight-semibold"
                >
                  Pricing
                </Link>
                <div className="navbar-item">
                  <Link to="/get-started" className="button is-primary">
                    Sign up
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </nav>
    </div>
  )
}

export default HeroHead
