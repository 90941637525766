import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from '@fortawesome/free-solid-svg-icons'

const Footer = () => (
  <footer className="footer">
    <div className="content has-text-centered">
      <p>
        <Link to="/get-started">Sign up</Link>&nbsp;&bull;&nbsp;
        <Link to="/api">API</Link>&nbsp;&bull;&nbsp;
        <Link to="/pricing">Pricing</Link>&nbsp;&bull;&nbsp;
        <Link to="/about">About</Link>&nbsp;&bull;&nbsp;
        <Link to="/policy">Privacy Policy</Link>&nbsp;&bull;&nbsp;
        <Link to="/faq">FAQ</Link>&nbsp;&nbsp;|&nbsp;	
      © {new Date().getFullYear()}, Built with <FontAwesomeIcon icon={faHeart} /> by
      {` `}
      <a href="https://www.venuebox.cloud">VenueBox Ltd</a>
      </p>
    </div>
  </footer>
)

export default Footer
